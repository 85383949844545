<template>
  <div class="intro-screen">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none">
      <clipPath
        id="clipPath"
        clipPathUnits="objectBoundingBox">
        <path
          id="target"
          fill="pink"
          d="M0 0 0 1 Q0.5 1 1 1 L1 0 Z"/>
      </clipPath>
    </svg>
    <div class="intro-screen__bg">
      <div class="intro-screen__content">
        <p>“И дам им одно сердце<br> и один путь”</p>
        <span class="subtitle">Иер. 32:39</span>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  name: 'IntroScreen',

  mounted () {
    document.body.style.overflow = 'hidden'
    anime({
      targets: '#target',
      d: [
        { value: 'M0 0 0 1 Q0.5 0.85 1 1 L1 0 Z' },
        { value: 'M0 0 0 1 Q0.5 1 1 1 L1 0 Z' }
      ],
      delay: 3000,
      duration: 1000,
      easing: 'easeOutCubic',
      loop: false
    })
    setTimeout(() => {
      this.$emit('intro')
      document.body.style.overflow = 'auto'
    }, 4000)
  }
}
</script>

<style lang="scss" scoped>
  .intro-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 15;

    @media screen and (max-width: 800px) {
      position: fixed;
    }

    &__bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #5A6C82;
      clip-path: url("#clipPath");
      animation: moveup 1s cubic-bezier(0.76, 0, 0.24, 1) forwards;
      animation-delay: 3s;
    }

    &__content {
      position: fixed;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      color: #fff;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      font-family: 'History Pro';
      line-height: 3.6rem;
      animation: fadein 3s forwards;
      animation-delay: .5s;

      .subtitle {
        font-size: 1.4rem;
        margin-top: 1.5rem;
      }
    }
  }

  @keyframes moveup {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
