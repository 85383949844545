<template lang="pug">
  header.app-header
    img.app-header__logo(
      :style="{ visibility: showLogo ? 'visible' : 'hidden' }"
      src="@/assets/svg/invito.svg")
    button.btn.btn--ghost.btn--icon(
      @click="openMenu")
      img.app-header__menu(src="@/assets/svg/menu.svg")
</template>

<script>
export default {
  name: 'AppHeader',

  computed: {
    showLogo () {
      return !this.$route.path
        .includes('colors')
    }
  },

  methods: {
    openMenu () {
      this.$store.dispatch('openMenu')
    }
  }
}
</script>

<style lang="scss">
  .app-header {
    width: 100%;
    position: absolute;
    padding: 0 4rem;
    top: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;

    &__logo {
      width: 7.2rem;
    }

    &__menu {
      width: 3rem;
    }
  }
</style>
